<template>
  <b-modal
      id="approveModal"
      centered hide-footer
      :title="title"
      ref="approveModal"
  >
    <b-card-text class="text-center">
        <template v-if="action == 'send_offer' && invoice.status == 12 && invoice.msg">
            <p><strong style="font-size: 18px; color: red;">{{invoice.msg}}</strong></p>
            <p><strong>{{$t('reminder_offer_problems')}}</strong></p>

        </template>

        <strong>
            {{textContent }}
        </strong>
    </b-card-text>
    <hr />
    <b-row>
      <b-col v-if="typeof showInput != 'undefined'" cols="12">
        <b-form-group
            id="comment-group"
            :label="$t('comment') + ':'"
            label-for="comment">
          <b-form-textarea
              id="comment"
              v-model="showInput"
              required
          >
          </b-form-textarea>
        </b-form-group>
      </b-col>
      <b-col v-if="title == 'Remove'" cols="12">
        <b-form-group
            id="reason-group"
            :label="$t('comment') + ':'"
            label-for="comment">
          <b-form-textarea
              id="reason"
              v-model="reason"
              required
          >
          </b-form-textarea>
        </b-form-group>
      </b-col>
      <b-col class="text-center">
        <b-button variant="danger" size="sm" @click="$bvModal.hide('approveModal')" class="mr-2">
          <feather-icon icon="XIcon"></feather-icon>
        </b-button>
        <b-button variant="primary" size="sm" @click="approve()">
          <feather-icon icon="CheckIcon"></feather-icon>
        </b-button>
      </b-col>
    </b-row>
  </b-modal>

</template>

<script>
import { BModal, BButton,  BCardText, BRow, BCol, BFormGroup, BFormTextarea } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: "ApproveModal",
  components: {
    BButton,BModal, BCardText, BRow, BCol, BFormGroup, BFormTextarea
  },
  data() {
    return {
      reason: '',
    }
  },
  directives: {
    Ripple,
  },
  props: ['textContent','title','showInput', 'action', 'invoice'],
  methods: {
    approve(){
      this.$parent.showInput = this.showInput;
      this.$parent.approve = true;
      this.$parent.reason = this.reason;
      this.$parent.callApproveModalAction();
      this.$refs['approveModal'].hide()
    }
  }
}
</script>

<style scoped>
</style>
